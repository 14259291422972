import { ApprenticeAuth } from '@/components/shared/Apprentice/ApprenticeAuth'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { usePersistRedirectUrl } from 'src/hooks'

const Login: NextPage = () => {
  const router = useRouter()
  usePersistRedirectUrl(router)
  return <ApprenticeAuth />
}

export default Login
