import { loginRedirect } from '@qureos/lib'
import { NextRouter } from 'next/router'

export const usePersistRedirectUrl = (router: NextRouter): any => {
  const redirectUrl = router.query?.redirectUrl
  if (redirectUrl) {
    loginRedirect('set', redirectUrl as string)
  }
  return { redirectUrl }
}
